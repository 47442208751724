<app-navbar></app-navbar>

<section class="parallax-bg fixed-bg view-height-100vh lg-section"
    style="background-image: url('assets/images/wetransfer/banner.png')" id="home">
    <div class="parallax-overlay"></div>
    <div class="hero-text-wrap xxl-screen transparent-bg">
        <div class="hero-text">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 centerize-col">
                        <div class="all-padding-50 text-center white-color">
                            <h1>WAOBIT</h1>
                            <h2 class="font-100 raleway-font">LABORATORIO DE MARKETING Y TRANSFORMACIÓN DIGITAL</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg xs-pb-100" id="features">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of features;" class="col-md-4 col-sm-4 col-xs-12 mb-30 hover-effect">
                <div [ngClass]="item.featureBox">
                    <i [ngClass]="item.icon"></i>
                    <h4 class="mt-0 font-600">{{item.title}}</h4>
                    <p class="font-400">{{item.desc}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen">
                    <img src="assets/images/wetransfer/somos.png" class="img-responsive border-radius-25"
                        alt="about-heading-1" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12 text-left d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mt-50">
                        <h2 class="raleway-font default-color mt-0">En Waobit, nos apasionamos por la tecnología y la
                            innovación.</h2>
                        <h1 class="raleway-font mt-30 font-50px font-300">¿Quiénes somos?</h1>
                    </div>
                    <p>Somos un equipo dedicado a ayudar a empresas a destacarse en el entorno digital. Con servicios
                        integrales en marketing digital, desarrollo tecnológico y mensajería masiva, ofrecemos
                        soluciones que impulsan el crecimiento y la transformación de nuestros clientes.</p>
                    <p>Nuestro objetivo es convertirnos en aliados estratégicos de nuestros clientes, proporcionándoles
                        soluciones integrales de marketing digital, desarrollo tecnológico y comunicación masiva que
                        impulsen su crecimiento.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg pt-80 pb-80"
    style="background-image: url('assets/images/background/parallax-bg.jpg')">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-50 white-color text-center">
                    <h1 class="raleway-font remove-margin font-50px font-300">Principios</h1>
                </div>
            </div>
        </div>
        <div class="row our-process-style-02">
            <div *ngFor="let step of steps" class="col-md-3 col-sm-6 col-xs-12 line xs-mb-30 sm-mb-30">
                <div class="icon-wrap white-bg">
                    <div class="icon">
                        <i [ngClass]="step.icon"></i>
                    </div>
                </div>
                <div class="text-center white-color">
                    <h4 class="font-500">{{step.title}}</h4>
                    <p class="mb-0">{{step.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg pb-80" id="services">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">What We Offer</h2>
                    <h1 class="raleway-font">Our Services</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>

        <div class="row mt-50">
            <div *ngFor="let service of services"
                class="col-md-4 col-sm-4 col-xs-12 mb-30 feature-box text-center hover-effect">
                <div class="gradient-bg-icon mb-20">
                    <i [ngClass]="service.icon"></i>
                </div>
                <h5 class="mt-10">{{service.title}}</h5>
                <p class="font-400">{{service.desc}}</p>
            </div>
        </div>

    </div>
</section>

<section class="gray-bg" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Somos Waobit</h2>
                    <h1 class="raleway-font">Transformación Digital</h1>
                </div>
                <p>Por nuestro amplio conocimiento, creamos plataformas digitales en el lenguaje de desarrollo que más
                    le convenga a tu empresa.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-7 col-xs-12">
                <img class="img-responsive" src="assets/images/responsive-screen-2.png" alt="laptop">
            </div>
            <div class="col-md-5 mt-30 col-xs-12">
                <div class="skillbar-wrap skillbar-style-01">
                    <div *ngFor="let skill of skills">
                        <div class="skillbar">
                            <span class="skillbar-title text-uppercase">{{ skill.name }}</span>
                            <p class="skillbar-bar gradient-bg" role="progressbar" [style.width]="skill.level + '%'"
                                [attr.aria-valuenow]="skill.level" aria-valuemin="0" aria-valuemax="100"></p>
                            <span class="skill-bar-percent">{{ skill.level }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gradient-bg xs-pb-60">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-left">
        <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/bg-left-img-2.jpg);">
            <div class="video-box_overlay">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <a (click)="openPopup()">
                            <div class="play-button"><i class="tr-icon ion-android-arrow-dropright"></i></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="col-md-6 col-sm-6 offset-md-6 offset-md-6">
            <div class="col-inner spacer white-color text-center">
                <h2 class="raleway-font mt-0 font-20px xs-font-17px">Our Amazing Story</h2>
                <h1 class="raleway-font mt-0 font-50px font-300 xs-font-27px">Watch Our Video</h1>
                <p>Objectively innovate empowered manufactured products whereas parallel platforms.<br>
                    Holisticly predominate extensible testing procedures for reliable supply chains. </p>
                <p><a href="javascript:void(0)" class="btn btn-md btn-square btn-light-outline mt-30">Meet The Team</a>
                </p>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/sU3FkzUKHXU?si=vhtmR7XG-9HXU6Ij"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='icofont icofont-close'></i>
        </button>
    </div>
</div>

<section class="grey-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Soluciones integrales</h2>
                    <h1 class="raleway-font">Nuestro Portafolio de Servicios</h1>
                </div>
                <p>Ofrecemos servicios personalizados, adaptados a las necesidades específicas de cada cliente,
                    brindando asesoría integral para asegurar que cada decisión impulse resultados positivos y
                    sostenibles en su empresa.</p>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row mt-50">
            <div *ngFor="let portfolio of portfolios" class="col-md-3 col-sm-6 portfolio with-spacing">
                <div class="portfolio-item border-white-15">
                    <a href="javascript:void(0)">
                        <img src="assets/images/portfolio/grid/{{portfolio.img}}" alt="">
                        <div class="portfolio-info gradient-bg">
                            <div class="centrize">
                                <div class="v-center white-color">
                                    <h3>{{portfolio.title}}</h3>
                                    <p>{{portfolio.subTitle}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background-image: url('assets/images/background/parallax-bg-2.jpg')">
    <div class="gradient-overlay-bg-two"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="font-400 white-color raleway-font xs-font-40px">Siente cómo tu negocio evoluciona al
                            ritmo de un mundo digital.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="grey-bg" id="pricings">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title mb-50">
                    <h2 class="raleway-font default-color">Selecciona el que vaya contigo</h2>
                    <h1 class="raleway-font">Nuestros planes ideales</h1>
                </div>
            </div>
        </div>
        <div class="row mt-50">
            <div *ngFor="let price of pricing; let i = index"
                class="col-md-4 pricing-table col-sm-4 xs-mb-30 hover-effect">
                <div
                    class="pricing-box border-radius-25 {{ i === 1 ? 'pricing-box-gradient-two' : 'pricing-box-gradient' }} text-center white-color">
                    <h3 class="mb-0 raleway-font">{{price.title}}</h3>
                    <h4>{{price.subTitle}}</h4>
                    <h2 class="font-60px sm-font-40px"><sup>{{price.currency}}</sup><span>{{price.amount}}</span></h2>
                    <ul class="list-style-02">
                        <li *ngFor="let feature of price.features">{{ feature }}</li>
                    </ul>
                    <div class="pricing-box-bottom"> <a [ngClass]="price.btnClass">{{price.btnText}}</a> </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!--section class="white-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title mb-50">
                    <h2 class="raleway-font default-color">What Our Client Says</h2>
                    <h1 class="raleway-font">Our Testimonials</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <owl-carousel-o [options]="customOptionsThree" class="testimonial owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreThree">
                        <div class="testimonial-item">
                            <div class="testimonial-content border-radius-25">
                                <img class="img-responsive rounded-circle text-center" src="{{slide.image}}" alt="" />
                                <h4 class="font-600 mb-0 raleway-font">{{slide.title}}</h4>
                                <span class="default-color font-14px">{{slide.subTitle}}</span>
                                <div class="clearfix mb-20"></div>
                                <i class="icofont icofont-quote-left font-50px default-color mt-20"></i>
                                <p class="mt-20 line-height-26 font-14px">{{slide.description}}</p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<div class="grey-bg pt-120 pb-120">
    <div class="container">
        <div class="row">
            <div class="client-slider">
                <owl-carousel-o [options]="customOptionsFour" class="owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreFour">
                        <div class="client-logo">
                            <img class="img-responsive" src="{{slide.image}}" alt="01" />
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>

    </div>
</div-->

<section class="gradient-bg-two xs-pb-60" id="blog">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-right">
        <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/wetransfer/blog.png);"></div>
    </div>
    <div class="container-fluid">
        <div class="col-md-6 col-sm-6">
            <div class="col-inner spacer white-color text-center">
                <h2 class="raleway-font mt-0 font-20px xs-font-17px">Lee nuestro blog</h2>
                <h1 class="raleway-font mt-0 font-50px font-300 xs-font-27px">¿Qué es la transformación Digital?</h1>
                <p>La transformación digital es un proceso que cambia por completo la forma en que una organización ofrece valor a sus clientes…</p>
                <p><a routerLink="/blog-details" class="btn btn-md btn-square btn-light-outline mt-30">Ver detalles del blog</a></p>
            </div>
        </div>
    </div>
</section>

<section class="white-bg transition-none" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">¿Quieres que tu empresa evolucione transformándose digitalmente?</h2>
                    <h1 class="raleway-font">Contáctanos ahora</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-70">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Ubicación</h2>
                            <p class="mt-30">Guayaquil - Ecuador
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Contácto</h2>
                            <p class="mb-0 mt-30">+593 99 403 2211</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Correo</h2>
                            <p class="mb-0 mt-30">info&#64;waobit.com</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Soporte</h2>
                            <p class="mb-0 mt-30">soporte&#64;waobit.com</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12 social-icons-style-01">
                        <ul class="md-icon left-icon">
                            <li *ngFor="let social of socialMedia">
                                <a [ngClass]="social.iconClass" href="javascript:void(0)"><i
                                        [ngClass]="social.icon"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <form (submit)="sendEmail($event)" class="contact-form-style-01" #contactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_name">Nombre</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userName.invalid && userName.touched" name="user_name"
                                    class="md-input" id="name" placeholder="Nombre *" [(ngModel)]="formData.user_name"
                                    #userName="ngModel" required>
                                <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                                    Nombre es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_email">Correo</label>
                                <input type="email" minlength="2" maxlength="35"
                                    [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                                    class="md-input" id="email" placeholder="Correo *" [(ngModel)]="formData.user_email"
                                    #userEmail="ngModel" required email>
                                <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                                    Un correo valido es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_company">Empresa</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userCompany.invalid && userCompany.touched" name="user_company"
                                    class="md-input" id="company" placeholder="Empresa *" [(ngModel)]="formData.user_company"
                                    #userCompany="ngModel" required>
                                <div *ngIf="userCompany.invalid && userCompany.touched" class="invalid-feedback mb-10">
                                    Empresa es requerida.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_phone">Teléfono</label>
                                <input type="phone" minlength="2" maxlength="35"
                                    [class.is-invalid]="userPhone.invalid && userPhone.touched" name="user_phone"
                                    class="md-input" id="phone" placeholder="Teléfono *" [(ngModel)]="formData.user_phone"
                                    #userPhone="ngModel" required>
                                <div *ngIf="userPhone.invalid && userPhone.touched" class="invalid-feedback mb-10">
                                    Un teléfono es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                                <label class="visually-hidden" for="message">Detalles</label>
                                <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                                    class="md-textarea" id="message" rows="3" placeholder="Detalles"
                                    [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                                <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                                    Detalles son requeridos.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="text-left mt-20">
                                <button type="submit" name="submit"
                                    class="btn btn-outline btn-md btn-circle btn-animate remove-margin"
                                    [disabled]="contactForm.invalid" value="Send">
                                    <span>Enviar mensaje <i class="ion-android-arrow-forward"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>